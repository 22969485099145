import store from "@/store";

export const FransApi = {
    authenticate,
    fetchCategories,
    fetchCategory,
    createCategory,
    editCategory,
    deleteCategory,
    fetchCustomizations,
    fetchCustomization,
    createCustomization,
    editCustomization,
    deleteCustomization,
    fetchProducts,
    fetchProduct,
    createProduct,
    editProduct,
    deleteProduct,
    fetchPlaces,
    fetchPlace,
    createPlace,
    editPlace,
    deletePlace,
    fetchUsers,
    fetchUser,
    createUser,
    editUser,
    deleteUser,
    uploadFile,
    fetchOrders,
    fetchOrder,
    updateOrder,
    fetchOrderPayment,
    fetchSummaryStatistics,
    fetchTopProducts,
    fetchDailyRevenue,
    fetchMonthlyRevenue,
    fetchDailyOrdersCount,
    fetchMonthlyOrdersCount
}

async function request(endpoint, method, body = null) {
    console.log(process.env)
    console.log(`${process.env.VUE_APP_BACKEND_ENDPOINT}${endpoint}`)
    let url = new URL(`${process.env.VUE_APP_BACKEND_ENDPOINT}${endpoint}`)
    let response = await fetch(url, {
        method: method,
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${store.getters.accessToken}`, 'ngrok-skip-browser-warning': true },
        body: body != null ? JSON.stringify(body) : null
    })

    if (!response.ok) {
        console.warn(`Could not perform request on ${endpoint}.`, response.statusText);
        return null
    }

    return response.json()
}

async function authenticate(email, password) {
    let response = await request('/auth/login', 'POST', {
        email: email,
        password: password
    })
    return response
}

async function fetchProducts(page = 1, limit = 10, category = null, search = null) {
    var endpoint = `/products?page=${page}&limit=${limit}`

    if (category) {
        endpoint += `&category=${category}`
    }

    if (search) {
        endpoint += `&search=${search}`
    }

    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function fetchProduct(id) {
    let response = await request(`/products/${id}`, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function createProduct(data) {
    let response = await request(`/products`, 'POST', data)

    if (response == null) {
        return null
    }

    return response
}

async function editProduct(id, data) {
    let response = await request(`/products/${id}`, 'PUT', data)

    if (response == null) {
        return null
    }

    return response
}

async function deleteProduct(id) {
    let response = await request(`/products/${id}`, 'DELETE')

    if (response == null) {
        return null
    }

    return response
}

async function fetchCategories(page = 1, limit = 10, search = null) {
    var endpoint = `/categories?page=${page}&limit=${limit}`

    if (search) {
        endpoint += `&search=${search}`
    }

    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function fetchCategory(id) {
    let response = await request(`/categories/${id}`, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function createCategory(data) {
    let response = await request(`/categories`, 'POST', data)

    if (response == null) {
        return null
    }

    return response
}

async function editCategory(id, data) {
    let response = await request(`/categories/${id}`, 'PUT', data)

    if (response == null) {
        return null
    }

    return response
}

async function deleteCategory(id) {
    let response = await request(`/categories/${id}`, 'DELETE')

    if (response == null) {
        return null
    }

    return response
}

async function fetchCustomizations(page = 1, limit = 10, search = null) {
    var endpoint = `/customizations?page=${page}&limit=${limit}`

    if (search) {
        endpoint += `&search=${search}`
    }

    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function fetchCustomization(id) {
    let response = await request(`/customizations/${id}`, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function createCustomization(data) {
    data.options.forEach(option => {
        if (option._id.includes('new')) {
            delete option._id
        }
    })

    let response = await request(`/customizations`, 'POST', data)

    if (response == null) {
        return null
    }

    return response
}

async function editCustomization(id, data) {
    data.options.forEach(option => {
        if (option._id.includes('new')) {
            delete option._id
        }
    })

    let response = await request(`/customizations/${id}`, 'PUT', data)

    if (response == null) {
        return null
    }

    return response
}

async function deleteCustomization(id) {
    let response = await request(`/customizations/${id}`, 'DELETE')

    if (response == null) {
        return null
    }

    return response
}

async function fetchPlaces(page = 1, limit = 10, search = null) {
    var endpoint = `/places?page=${page}&limit=${limit}`

    if (search) {
        endpoint += `&search=${search}`
    }

    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function fetchPlace(id) {
    let response = await request(`/places/${id}`, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function createPlace(data) {
    let response = await request(`/places`, 'POST', data)

    if (response == null) {
        return null
    }

    return response
}

async function editPlace(id, data) {
    data.opening_hours.forEach(opening_hour => {
        delete opening_hour._id
    })

    let response = await request(`/places/${id}`, 'PUT', data)

    if (response == null) {
        return null
    }

    return response
}

async function deletePlace(id) {
    let response = await request(`/places/${id}`, 'DELETE')

    if (response == null) {
        return null
    }

    return response
}

async function fetchUsers(page = 1, limit = 10, search = null) {
    var endpoint = `/users?page=${page}&limit=${limit}`

    if (search) {
        endpoint += `&search=${search}`
    }

    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function fetchUser(id) {
    let response = await request(`/users/${id}`, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function createUser(data) {
    let response = await request(`/users`, 'POST', data)

    if (response == null) {
        return null
    }

    return response
}

async function editUser(id, data) {
    let response = await request(`/users/${id}`, 'PUT', data)

    if (response == null) {
        return null
    }

    return response
}

async function deleteUser(id) {
    let response = await request(`/users/${id}`, 'DELETE')

    if (response == null) {
        return null
    }

    return response
}

async function uploadFile(file, folder = null) {
    let params = folder ? `?folder=${folder}` : ''
    let url = new URL(`https://6684-86-234-134-113.ngrok-free.app/files/upload${params}`)
    let data = new FormData()
    data.append('file', file)

    let response = await fetch(url, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${store.getters.accessToken}`, 'ngrok-skip-browser-warning': true },
        body: data
    })

    if (response == null) {
        return null
    }

    return await response.json()
}

async function fetchOrders(page = 1, limit = 10, search = null, statuses = [], place = null) {
    var endpoint = `/orders?page=${page}&limit=${limit}`

    if (search) {
        endpoint += `&search=${search}`
    }

    if (statuses.length > 0) {
        statuses.forEach ((status) => {
            endpoint += `&statuses[]=${status}`
        })
    }

    if (place) {
        endpoint += `&place=${place}`
    }

    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function fetchOrder(id) {
    let endpoint = `/orders/${id}`
    let response = request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response 
}

async function updateOrder(id, order) {
    let endpoint = `/orders/${id}`
    let response = request(endpoint, 'PUT', order)

    if (response == null) {
        return null
    }

    return response 
}

async function fetchOrderPayment(id) {
    let endpoint = `/payments/orders/${id}`
    let response = request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response 
}

async function fetchSummaryStatistics(place_id = null, start_date = null, end_date = null) {
    var endpoint = '/statistics/summary'
    var parameters = []

    if (place_id) {
        parameters.push(`place_id=${place_id}`)
    }

    if (start_date) {
        parameters.push(`start_date=${start_date}`)
    }

    if (end_date) {
        parameters.push(`end_date=${end_date}`)
    }

    if (parameters.length > 0) {
        endpoint += `?${parameters.join('&')}`
    }
    
    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function fetchTopProducts(place_id = null, start_date = null, end_date = null, limit = 10) {
    var endpoint = '/statistics/top-products'
    var parameters = []

    if (place_id) {
        parameters.push(`place_id=${place_id}`)
    }

    if (start_date) {
        parameters.push(`start_date=${start_date}`)
    }

    if (end_date) {
        parameters.push(`end_date=${end_date}`)
    }

    parameters.push(`limit=${limit}`)

    if (parameters.length > 0) {
        endpoint += `?${parameters.join('&')}`
    }
    
    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function fetchDailyRevenue(place_id = null, start_date = null, end_date = null) {
    var endpoint = '/statistics/revenue-by-day'
    var parameters = []

    if (place_id) {
        parameters.push(`place_id=${place_id}`)
    }

    if (start_date) {
        parameters.push(`start_date=${start_date}`)
    }

    if (end_date) {
        parameters.push(`end_date=${end_date}`)
    }

    if (parameters.length > 0) {
        endpoint += `?${parameters.join('&')}`
    }
    
    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function fetchMonthlyRevenue(place_id = null, start_date = null, end_date = null) {
    var endpoint = '/statistics/revenue-by-month'
    var parameters = []

    if (place_id) {
        parameters.push(`place_id=${place_id}`)
    }

    if (start_date) {
        parameters.push(`start_date=${start_date}`)
    }

    if (end_date) {
        parameters.push(`end_date=${end_date}`)
    }

    if (parameters.length > 0) {
        endpoint += `?${parameters.join('&')}`
    }
    
    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function fetchDailyOrdersCount(place_id = null, start_date = null, end_date = null) {
    var endpoint = '/statistics/orders-count-by-day'
    var parameters = []

    if (place_id) {
        parameters.push(`place_id=${place_id}`)
    }

    if (start_date) {
        parameters.push(`start_date=${start_date}`)
    }

    if (end_date) {
        parameters.push(`end_date=${end_date}`)
    }

    if (parameters.length > 0) {
        endpoint += `?${parameters.join('&')}`
    }
    
    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}

async function fetchMonthlyOrdersCount(place_id = null, start_date = null, end_date = null) {
    var endpoint = '/statistics/orders-count-by-month'
    var parameters = []

    if (place_id) {
        parameters.push(`place_id=${place_id}`)
    }

    if (start_date) {
        parameters.push(`start_date=${start_date}`)
    }

    if (end_date) {
        parameters.push(`end_date=${end_date}`)
    }

    if (parameters.length > 0) {
        endpoint += `?${parameters.join('&')}`
    }
    
    let response = await request(endpoint, 'GET')

    if (response == null) {
        return null
    }

    return response
}